<template>
  <div>
    <AppBar :title="$t('delivery.title')"/>
    <v-card flat class="rounded-xl pt-2 pb-4">
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <h3>{{ $t('delivery.options') }}</h3>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>{{ $t('delivery.name') }}</th>
                    <th>{{ $t('delivery.price') }}</th>
                    <th>{{ $t('delivery.icon') }}</th>
                    <th>{{ $t('delivery.leadTime') }}</th>
                    <th>{{ $t('delivery.deliveryTime') }}</th>
                    <th>{{ $t('delivery.active') }}</th>
                    <th style="width: 40px"></th>
                    <th style="width: 40px"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="method in methods" :key="method.id">
                    <td>{{ method.name }}</td>
                    <td>{{ method.price.toFixed(2) }} {{ getLocalCurrency }}</td>
                    <td><v-img contain width="25" :src="url+method.icon" /></td>
                    <td>{{ method.leadTime }}h</td>
                    <td>{{ method.deliveryTime }}d</td>
                    <td style="width: 50px">
                      <v-switch :input-value="method.active" :disabled="!method.isEditable" @change="changeStatus(method)" color="secondary" class="mt-0" hide-details></v-switch>
                    </td>
                    <td>
                        <v-btn icon color="primary" :disabled="!method.isEditable" @click="editDialog(method)" rounded>
                            <v-icon dark>mdi-pencil-outline</v-icon>
                        </v-btn>
                    </td>
                    <td>
                      <v-btn icon color="primary" :disabled="!method.isEditable" @click="confirmDelete(method)" rounded>
                        <v-icon dark>mdi-close</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-btn depressed class="mt-2" color="secondary" @click="addDialog" rounded>{{ $t('buttons.add') }}</v-btn>
          </v-col>
          <v-col v-if="orderConfiguration" cols="12">
            <v-row>
              <v-col cols="2">
                <h3 class="mb-4">{{ $t('delivery.charges') }}</h3>
                <v-form ref="addForm" v-model="valid">
                  <v-text-field v-model.number="orderConfiguration.serviceFee" type="number" dense :label="$t('delivery.serviceCharge')" class="py-2" :rules="required"></v-text-field>
                  <v-btn depressed class="mt-2" color="secondary" :loading="loading" :disabled="loading || !valid" @click="saveFee" rounded>{{ $t('buttons.save') }}</v-btn>
                </v-form>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <Delete ref="confirmDeleteMethod"  @refresh="getMethods" />
    <Add ref="addMethod" @refresh="getMethods"></Add>
    <Edit ref="editMethod" @refresh="getMethods"></Edit>
  </div>
</template>
<script>
import Service from '@/services'
import Delete from './Dialogs/delete.vue'
import Add from './Dialogs/add.vue'
import Edit from './Dialogs/edit.vue'
export default {
  components: { Delete, Add, Edit },
  name: 'Users',
  data () {
    return {
      valid: false,
      methods: [],
      orderConfiguration: null,
      required: [((v) => v !== null && v !== undefined) || this.$t('validation.fieldRequired')]
    }
  },
  async created () {
    await this.getMethods()
    await this.getOrderConfiguration()
  },
  computed: {
    url () {
      return process.env.VUE_APP_BASE + 'uploads/'
    },
    getLocalCurrency () {
      return localStorage.getItem('currency')
    }
  },
  methods: {
    async getMethods () {
      this.loading = true
      const countryCode = localStorage.getItem('tenantValue')
      try {
        const { data } = await Service.get(`/ShippingMethods/Management?countryCode=${countryCode}`)
        this.methods = data
      } catch (error) {}
      this.loading = false
    },
    async getOrderConfiguration () {
      this.loading = true
      const countryCode = localStorage.getItem('tenantValue')
      try {
        const { data } = await Service.get(`/OrderConfiguration/Management?countryCode=${countryCode}`)
        const country = { countryCode }
        this.orderConfiguration = { ...data, ...country }
      } catch (error) {}
      this.loading = false
    },
    async changeStatus (method) {
      if (method.active) {
        await this.dectivateMethod(method.id)
      } else {
        await this.activateMethod(method.id)
      }
    },
    async activateMethod (id) {
      this.loading = true
      await Service.put(`/ShippingMethods/Activate?id=${id}`)
      await this.getMethods()
      this.loading = false
    },
    async dectivateMethod (id) {
      this.loading = true
      await Service.put(`/ShippingMethods/Dectivate?id=${id}`)
      await this.getMethods()
      this.loading = false
    },
    async confirmDelete (method) {
      this.$refs.confirmDeleteMethod.show(method)
    },
    addDialog () {
      this.$refs.addMethod.show()
    },
    editDialog (method) {
      this.$refs.editMethod.show(method)
    },
    async saveFee () {
      this.$refs.addForm.validate()
      if (!this.valid) return

      this.loading = true
      try {
        await Service.put('OrderConfiguration', this.orderConfiguration)
      } catch (error) {}
      await this.getOrderConfiguration()
      this.loading = false
    }
  }
}
</script>
