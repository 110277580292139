<template>
    <v-dialog v-if="method" v-model="dialog" persistent max-width="350" content-class="rounded-xl">
      <v-card class="rounded-xl">
        <v-card-title class="text-h5">
          {{$t('delivery.deleteDeliveryConfirm')}}
        </v-card-title>
        <v-card-text>
            {{ method.name }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="close" :loading="loading" :disabled="loading" rounded>
            {{ $t('buttons.delete') }}
          </v-btn>
          <v-btn @click="dialog = false" :disabled="loading" rounded>
            {{ $t('buttons.cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
import Service from '@/services'
export default {
  name: 'Confirm',
  data () {
    return {
      dialog: false,
      method: null,
      loading: false
    }
  },
  methods: {
    show (method) {
      if (method) {
        this.method = method
        this.dialog = true
      }
    },
    async close () {
      this.loading = true
      try {
        await Service.delete('/ShippingMethods', this.method.id)
        this.dialog = false
        this.$emit('refresh')
      } catch (error) {}

      this.loading = false
    }
  }
}
</script>
