<template>
  <v-dialog v-if="method" v-model="dialog" persistent max-width="350" content-class="rounded-xl">
    <v-card class="rounded-xl">
      <v-card-title class="text-h5">
        {{ $t('users.edit') }}
      </v-card-title>
      <v-card-text class="mt-4">
        <v-form ref="addForm" v-model="valid">
          <v-text-field v-model="method.name" dense :label="$t('delivery.name')" class="py-2" :rules="required"></v-text-field>
          <v-text-field v-model.number="method.price" dense :label="$t('delivery.price')" class="py-2" type="number" :rules="priceRule"></v-text-field>
          <v-img width="20" :src="url+method.icon"></v-img>
          <v-file-input v-model="icon" :label="$t('delivery.icon')" accept="image"></v-file-input>
          <v-text-field v-model.number="method.leadTime" dense :label="$t('delivery.leadTime') + ' ' + $t('delivery.inHours')" class="py-2" type="number" :rules="required"></v-text-field>
          <v-text-field v-model.number="method.deliveryTime" dense :label="$t('delivery.deliveryTime') + ' ' + $t('delivery.inDays')" class="py-2" type="number" :rules="required"></v-text-field>
          <v-text-field v-model="method.deliveryDescription" dense :label="$t('delivery.additionalDeliveryDescription')" class="py-2" maxlength="70"></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" @click="add" :disabled="loading" :loading="loading" rounded>
          {{ $t('buttons.save') }}
        </v-btn>
        <v-btn @click="close" :disabled="loading" rounded>
          {{ $t('buttons.cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Service from '@/services'
export default {
  name: 'EditMethod',
  data () {
    return {
      dialog: false,
      valid: false,
      method: null,
      icon: null,
      loading: false,
      required: [(v) => !!v || this.$t('validation.fieldRequired')],
      priceRule: [v => !!v || this.$t('validation.fieldRequired')]
    }
  },
  computed: {
    url () {
      return process.env.VUE_APP_BASE + 'uploads/'
    }
  },
  methods: {
    show (method) {
      if (method) {
        this.method = JSON.parse(JSON.stringify(method))
        this.dialog = true
      }
    },
    async add () {
      this.$refs.addForm.validate()

      if (!this.valid) return

      this.loading = true

      const formData = new FormData()
      formData.append('Data',
        JSON.stringify({
          Name: this.method.name,
          Price: this.method.price,
          LeadTime: Math.abs(Math.trunc(this.method.leadTime)),
          DeliveryTime: Math.abs(Math.trunc(this.method.deliveryTime)),
          DeliveryDescription: this.method.deliveryDescription
        })
      )

      if (this.icon) {
        formData.append('Icon', this.icon)
      }

      await Service.put(`/ShippingMethods/${this.method.id}`, formData)

      this.loading = false
      this.$refs.addForm.reset()

      this.$emit('refresh')

      this.dialog = false
    },
    close () {
      this.$refs.addForm.reset()
      this.dialog = false
    }
  }
}
</script>
